/* eslint-disable react/display-name */
import * as React from "react";
import styled from "styled-components";
import moment from "moment";

import { CameraLocationHealthStatus, ICameraLocation } from "src/store/camera-locations/camera-locations.api";

import { Text, Icon as BaseIcon } from "..";

import { Table } from "../table/Table";
import { Row } from "hedron";
import { ColumnDef, PaginationState } from "@tanstack/react-table";
import LatestImageTable from "./latest-image-table";
import { CameraTotals } from "src/store/camera-locations/camera-locations.actions";
import { Icon } from "../table/Icon";
import type {
  Dispatch,
  SetStateAction
} from "react";
import { SortType } from "src/types/pagination";
import { styles } from "src/lib/styles";
import Spacer from "../Spacer";
import { ISiteRouterProps } from "src/containers/admin/edit-site";
import { RouteComponentProps } from "react-router-dom";
import { TableActionButton } from "../table/TableActionButton";
import RadioGroup from "../radio-button/formik-radio";

interface ICameraLocationListProps {
  history: RouteComponentProps<ISiteRouterProps>["history"];
  customerId?: string;
  rhsCallToAction?: JSX.Element; 
  cameraStatus?: string;
  setCameraStatus: (status: string | undefined) => void;
  cameraLocations: ICameraLocation[];
  count: number;
  totals: CameraTotals;
  onFetchData: () => void;
  loading?: boolean;
  pagination: PaginationState;
  setPagination: (pagination: PaginationState) => void;
  setSortOrder: Dispatch<SetStateAction<SortType<ICameraLocation>>>;
}

export enum CameraFilter {
  All = "all",
  Live = "live",
  Suspended = "suspended",
  Finished = "finished",
  Archived = "archived"
}

const CameraLocationList: React.FC<ICameraLocationListProps> = ({
  count,
  cameraLocations,
  onFetchData,
  cameraStatus,
  rhsCallToAction,
  setCameraStatus,
  history,
  loading,
  pagination,
  setPagination,
  totals,
  setSortOrder
}) => {
  const columns:Array<ColumnDef<ICameraLocation>> = [
    {
      header: "Ref",
      accessorFn: item => item.job_ref,
      size: 60,
      cell: item => {
        return (
          <div style={{
            textAlign: "left",
            height: "100%",
            display: "flex",
            placeItems: "center"
          }}>
            {item.row.original.job_ref}
          </div>
        );
      }
    },
    {
      header: "Name",
      accessorFn: item => item.name,
      size: 300,
      cell: item => {
        const customerName = item.row.original.site?.customer?.name;
        const siteName = item.row.original.site?.name;
        const customerLink = `/admin/customer/${item.row.original.site?.customer_id}`;
        const siteLink = `/admin/customer/${item.row.original.site?.customer_id}/site/${item.row.original.site_id}`;

        return (
          <div style={{
            textAlign: "left",
            height: "100%",
            display: "flex",
            placeItems: "center",
            marginLeft: "2px"
          }}>
            {customerName && siteName ? (
              <div> 
                <Styled.Link href={customerLink}>{customerName}</Styled.Link> / {" "}
                <Styled.Link href={siteLink}>{siteName}</Styled.Link>
                <p/>
                <div style={{
                  fontWeight: "bold",
                  fontSize: "18px",
                  paddingTop: "5px"
                }}>{" " + item.row.original.name}</div>
                
              </div>
            ) : item.row.original.name}
          </div>
        );
      }
    },
    {
      header: "Health status",
      accessorFn: item => item.health_status,
      size: 90,
      cell: item => {
        let statusColour = styles.healthyColour;
        let statusText = undefined;

        if (item.row.original.is_finished) {
          statusText = "finished";
          statusColour = styles.healthyColour;
        } else if (item.row.original.status === "archived") {
          statusText = "archived";
          statusColour = styles.lightBlue;
        } else {
          if (item.row.original.status === "suspended") {
            statusText = "suspended";
          }
          
          switch (item.row.original.health_status) {
            case CameraLocationHealthStatus.NoImages:
            case CameraLocationHealthStatus.VeryLowDiskSpace:
              statusColour = styles.dangerColour;
              break;
            case CameraLocationHealthStatus.LowVoltage:
            case CameraLocationHealthStatus.LowDiskSpace:
              statusColour = styles.warningColour;
              break;
            case CameraLocationHealthStatus.Healthy:
            default:
              statusColour = styles.healthyColour;
              break;
          }
        }

        return (
          <div style={{
            padding: "0.5rem",
            backgroundColor: statusColour,
            borderRadius: "0.5rem",
            display: "inline-block",
            color: statusColour === styles.lightBlue || statusColour === styles.warningColour ? "black" : "white"
          }}>
            <p>
              {statusText || item.row.original.health_status.replace(/_/g, " ")}
            </p>
          </div>
        );
      }
    },
    {
      header: "Hardware ref",
      accessorFn: item => item.hardware_ref,
      size: 100,
      cell: item => {
        return (
          <div style={{
            textAlign: "left",
            height: "100%",
            display: "flex",
            placeItems: "center"
          }}>
            {item.row.original.hardware_ref}
          </div>
        );
      }
    },
    {
      header: "Voltage",
      accessorFn: item => item.latest_voltage,
      size: 60,
      cell: item => {
        return (
          <div style={{
            textAlign: "left",
            height: "100%",
            display: "flex",
            placeItems: "center"
          }}>
            {item.row.original.latest_voltage}
          </div>
        );
      }
    },
    {
      header: "Temperature",
      accessorFn: item => item.latest_temperature,
      size: 100,
      cell: item => {
        return (
          <div style={{
            textAlign: "left",
            height: "100%",
            display: "flex",
            placeItems: "center"
          }}>
            {item.row.original.latest_temperature ? `${item.row.original.latest_temperature}˚C` : ""}
          </div>
        );
      }
    },
    {
      header: "Disk Space",
      accessorFn: item => item.latest_disk_space,
      size: 80,
      cell: item => {
        return (
          <div style={{
            textAlign: "left",
            height: "100%",
            display: "flex",
            placeItems: "center"
          }}>
            {item.row.original.latest_disk_space ? `${item.row.original.latest_disk_space}%` : ""}
          </div>
        );
      }
    },
    {
      header: "Settings",
      accessorFn: item => item.public && item.video_settings,
      size: 60,
      enableSorting: false,
      cell: item => {
        const customerId = item.row.original.site?.customer_id;
        const siteId = item.row.original.site_id;
        const cameraUUID = item.row.original.uuid;
        const publicLink = `/public/${customerId}/site/${siteId}/camera/${cameraUUID}/images`;
        const liveImageLink = `/customer/${customerId}/site/${siteId}/camera/${cameraUUID}/live-image`;
        const adminLiveImageLink = `/admin${liveImageLink}`;

        return (
          <div style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            flexDirection: "column",
            gap: "5px"
          }}>
            {item.row.original.public ? (
              <a href={publicLink} target="_blank" rel="noreferrer" style={{
                textDecoration: "inherit",
                color: "inherit"
              }}>
                <div style={{
                  borderRadius: "6px",
                  background: "#1C2637",
                  padding: "5px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "10px",
                  color: "#AFBBCC"
                }}>Public <Icon.CheckCircle size={20}/></div>
              </a>
        
            ) : null}
            {item.row.original.video_settings?.enabled ? (
             
              <div style={{
                borderRadius: "6px",
                background: "#1C2637",
                padding: "5px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px"
              }}>Video <Icon.CheckCircle size={20}/></div>
            
            ) : null} 

            <a href={liveImageLink} target="_blank" rel="noreferrer" style={{
              textDecoration: "inherit",
              color: "inherit"
            }}>
              <div style={{
                borderRadius: "6px",
                background: "#1C2637",
                padding: "5px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px"
              }}>Live Image <BaseIcon size={16} icon="openNew" className="icon" color="white"/></div>
            </a>

            <a href={adminLiveImageLink} target="_blank" rel="noreferrer" style={{
              textDecoration: "inherit",
              color: "inherit"
            }}>
              <div style={{
                borderRadius: "6px",
                background: "#1C2637",
                padding: "5px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px"
              }}>Admin Image <BaseIcon size={16} icon="openNew" className="icon" color="white"/></div>
            </a>
          </div>
          
        );
      }
    },
    {
      header: "Latest Image",
      accessorFn: item => item.latest_image,
      size: 80,
      enableSorting: true,
      cell: item => {
        return (
          <div>
            <LatestImageTable
              thumbnails={item.row.original.latest_image?.thumbnails}
              taken_at={item.row.original.latest_image?.taken_at}
              cameraLocationId={item.row.original.id}
              onFetchData={onFetchData}
            />
          </div>
        );
      }
    },
    {
      header: "Today's first capture",
      accessorFn: item => item.today_first_capture,
      size: 80,
      enableSorting: false,
      cell: item => {
        return (
          <div style={{
            textAlign: "left",
            height: "100%",
            display: "flex",
            placeItems: "center"
          }}>
            {item.row.original.today_first_capture ? moment
              .utc(item.row.original.today_first_capture)
              .format("HH:mm:ss") : ""}
          </div>
        );
      }
    },
    {
      header: "Actions",
      accessorFn: item => item.id,
      size: 100,
      enableSorting: false,
      enableResizing: false,
      cell: item => {
        const customerId = item.row.original.site?.customer_id;
        const siteId = item.row.original.site_id;
        const cameraUUID = item.row.original.uuid;

        return (
          <Styled.ActionContainer>
            <TableActionButton
              text="Manage"
              newTabPath={`/admin/customer/${customerId}/site/${siteId}/camera/${cameraUUID}`}
              onClick={() =>
                history.push(`/admin/customer/${customerId}/site/${siteId}/camera/${cameraUUID}`)
              }
            />
            <TableActionButton
              text="View"
              newTabPath={`/customer/${customerId}/site/${siteId}/camera/${cameraUUID}/images`}
              onClick={() =>
                history.push(`/customer/${customerId}/site/${siteId}/camera/${cameraUUID}/images`)
              }
            />
          </Styled.ActionContainer>
        );
      }
    }
  ];

  return (
    <div>
     
      <div style={{ marginBottom: 20 }}>
        <Row alignItems="center">
          <Styled.CameraPageTitle><Text fontSize="h5" weight="bold">Cameras</Text></Styled.CameraPageTitle>

          <RadioGroup
            options={{
              live: `Live (${totals.liveTotal})`,
              suspended: `Suspended (${totals.suspendedTotal})`,
              finished: `Finished (${totals.finishedTotal})`,
              archived: `Archived (${totals.archivedTotal})`,
              all: `All (${totals.allTotal})`
            }}
            value={cameraStatus || CameraFilter.All}
            onChange={value => setCameraStatus(value)}

          />
     
          <Spacer />
          
          {rhsCallToAction}

        </Row>

      </div>
      <Table<ICameraLocation>
        data={cameraLocations}
        columns={columns}
        refetchData={onFetchData}
      
        loading={loading}
        title="Cameras"
        pagination={pagination}
        setPagination={setPagination}
        totalDataCount={count}
        setSortOrder={setSortOrder}
      />
    </div>
  );
};

export const Styled = {
  CTAButton: styled.button`
    background: ${styles.primaryAccentColor};
    border: none;
    border-radius: 6px;
    color: ${styles.primaryDarkColor};
    padding: 8px 12px;
    cursor: pointer;
    &:disabled {
      background: ${styles.formBackground};
      cursor: not-allowed;
    }
  `,
  ActionContainer: styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (min-width: ${styles.mobileBreakPoint}) {
    display: flex;
    flex-direction: column;
    float: right;
    > :first-child {
      margin-bottom: 5px;
    }
    align-items: flex-end;
  }
`,
  CheckboxWrapper: styled.div`
  margin-bottom: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
`,
  CameraPageTitle: styled.div`
  margin-right: 20px;
  margin-bottom: 4px;
  display: none;
  @media screen and (min-width: ${styles.mobileBreakPoint}) {
    display: block;
  }
`,
  Link: styled.a`
  text-decoration: none;
  color: inherit;
  :hover {
   color: ${styles.primaryAccentColor};
  }
`,
  SearchContainer: styled.div`
display: flex;
flex-direction: row;
align-items: center;

@media screen and (max-width: ${styles.mobileBreakPoint}) {
margin-top: 10px;
}
`

};

export default CameraLocationList;
