import * as React from "react";
import { Text } from "..";
import { Checkbox } from "../checkbox/formik-checkbox";
import { Column, Row } from "hedron";
import { ICameraLocation } from "src/store/camera-locations/camera-locations.api";
import awsmobile from "src/aws-exports";
import { Storage } from "aws-amplify";
import { S3Image } from "src/components/s3-image/s3-image";

import { times } from "src/utils/times";
import { DropdownFormik, IDropdownProps } from "../dropdown-formik/dropdown-formik";
import TextInput from "../text-input/text-input";
import { ImageUploader } from "src/components/image-uploader";
import { toast } from "react-toastify";
import * as _ from "lodash";
import { S3Dropzone, S3DropzoneRef } from "../image-uploader/dropzone";

export const audioFilesBucket = `${process.env.REACT_APP_S3_BUCKET}/audio-files`;

// For now - manually add all the audio files from S3 - we should eventually create an Audio table
export const audioFiles: VideoGenerationSettings["audio"][] = [
  {
    s3Bucket: audioFilesBucket,
    s3Uri: "audio1.mp3"
  }
];

export const getAudioFileDisplayName = (s3Uri: string) => {
  const aliases = [
    {
      s3Uri: "audio1.mp3",
      displayName: "Upbeat #1"
    }
  ];

  return aliases.find(alias => alias.s3Uri === s3Uri)?.displayName || s3Uri;
};

interface CameraLocationVideoSettingsProps {
  cameraLocation: ICameraLocation | null;
}
type VideoGenerationSettings = ICameraLocation["video_settings"];

const defaultHeight = 1080;
const defaultWidth = 1920;

const defaultCropValues = {
  // This maintains the 16:9 aspect ratio
  height: 3375,
  width: 6000,
  offsetY: 250,
  offsetX: 0
};

const initialValues: VideoGenerationSettings = {
  enabled: false,
  start_time: "08:00",
  end_time: "16:00",
  resize: {
    width: defaultWidth,
    height: defaultHeight
  },
  watermark: undefined
};

export const CameraLocationVideoSettings = React.forwardRef<{videoCameraSettings: VideoGenerationSettings}, CameraLocationVideoSettingsProps>(({ cameraLocation }, ref) => {
  const [videoGenerationSettings, setVideoGenerationSettings] = React.useState<VideoGenerationSettings>({
    ...initialValues,
    ...cameraLocation?.video_settings,
    enabled: cameraLocation?.video_settings.enabled || false
  });

  const [watermarkEnabled, setWatermarkEnabled] = React.useState(false);
  const [cropEnabled, setCropEnabled] = React.useState(false);
  const bumperPrependRef = React.useRef<S3DropzoneRef>();
  const bumperAppendRef = React.useRef<S3DropzoneRef>();

  React.useEffect(() => {
    if (cameraLocation) {
      if (cameraLocation.video_settings) {
        setVideoGenerationSettings(cameraLocation.video_settings);

        if (cameraLocation.video_settings.watermark) {
          setWatermarkEnabled(true);
        }

        if (cameraLocation.video_settings.crop) {
          setCropEnabled(true);
        }
      } else {
        setVideoGenerationSettings(initialValues);
      }
    }
  }, [cameraLocation]);

  React.useImperativeHandle(
    ref, () => {
      const newVideoState = videoGenerationSettings;

      if ((bumperPrependRef.current?.file) || (bumperAppendRef.current?.file)) {
        newVideoState.bumper = {};

        if (bumperPrependRef.current?.file) {
          newVideoState.bumper.prepend = bumperPrependRef.current.file;
        }

        if (bumperAppendRef.current?.file) {
          newVideoState.bumper.append = bumperAppendRef.current.file;
        }
      }

      return ({ videoCameraSettings: newVideoState });
    }, [videoGenerationSettings]
  );

  /**
   * Upload file to s3 bucket
   * @param file
   * @param cameraLocation
   */
  const uploadToS3 = async (
    file: File, cameraLocation: ICameraLocation, type: "masks" | "watermarks"
  ): Promise<string | null> => {
    let result: any;

    try {
      const site = cameraLocation.site;
      const customer = cameraLocation.site && cameraLocation.site.customer;

      if (!cameraLocation || !site || !customer) {
        toast.error("Cannot upload to s3. Missing required info to upload to correct folder.");

        return null;
      }
      const path = `${customer.slug}/${site.slug}/${cameraLocation.job_ref}/${type}/${file.name}`;

      result = await Storage.put(
        path, file, { level: "public" }
      );
    } catch (error) {
      console.error(error);
    }
    const s3BucketPath = `https://${awsmobile.Storage.bucket}.s3.${awsmobile.Storage.region}.amazonaws.com/public/`;

    return `${s3BucketPath}${result.key}`;
  };

  const uploadPath = React.useMemo(() => {
    if (!cameraLocation) {
      return null;
    }
    const site = cameraLocation.site;
    const customer = cameraLocation.site && cameraLocation.site.customer;

    if (!cameraLocation || !site || !customer) {
      return null;
    }

    return `${customer.slug}/${site.slug}/${cameraLocation.job_ref}`;
  }, [cameraLocation]);

  const updateVideoState = React.useCallback((newState: Partial<VideoGenerationSettings>) => {
    setVideoGenerationSettings({
      ...videoGenerationSettings,
      ...newState
    });
  }, [videoGenerationSettings]);

  const dayOfWeekAsString = (dayIndex: number) => {
    return [
      "Sun",
      "Mon",
      "Tue",
      "Wed",
      "Thu",
      "Fri",
      "Sat"
    ][dayIndex - 1];
  };

  const audioDropdownValues: IDropdownProps["items"] = [];

  // Construct the audioDropdownValues array
  audioFiles.map(audioFile => audioDropdownValues.push({
    value: audioFile?.s3Uri || "",
    text: audioFile?.s3Uri ? getAudioFileDisplayName(audioFile.s3Uri) : ""
  }));

  // Callback function for updating each crop value in state
  const setCrop = React.useCallback((key: string, value: number) => {
    if (videoGenerationSettings) {
      setVideoGenerationSettings({
        ...videoGenerationSettings,
        crop:  {
          ...videoGenerationSettings.crop || defaultCropValues,
          [key]: value
        }
      });
    }
  }, [videoGenerationSettings]);

  // Set crop as undefined if cropEnabled is false
  React.useEffect(() => {
    const crop = cropEnabled ? { ...videoGenerationSettings.crop || cameraLocation?.video_settings.crop || defaultCropValues } : undefined;

    if (videoGenerationSettings) {
      setVideoGenerationSettings({
        ...videoGenerationSettings,
        crop
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cropEnabled]);

  return (
    <>
      <Row>
        <Column>
          <Text fontSize="h3" weight="bold">Video Generation</Text>
        </Column>
      </Row>
      <Row>
        <Column>
          <Checkbox
            onChange={() => updateVideoState({ enabled: !videoGenerationSettings.enabled })}
            checked={videoGenerationSettings.enabled}
            name={"video_settings"}
            label="enabled"/>
        </Column>
      </Row>

      {videoGenerationSettings.enabled && (
        <>
          <Row>
            <Column>
              <Text weight="bold">{"Days of week"}</Text>
            </Column>
          </Row>

          <div style={{ padding: "15px" }}>
            {[
              1,
              2,
              3,
              4,
              5,
              6,
              7
            ].map(weekDay => {
              return (
                <label
                  key={weekDay}
                  style={{ padding: "0 8px" }}
                >
                  <input
                    name="days_of_week"
                    type="checkbox"
                    value={weekDay}
                    checked={videoGenerationSettings.days_of_week && videoGenerationSettings.days_of_week.find(day => day === weekDay) ? true : false}
                    onChange={e => {
                      if (e.target.checked) {
                        updateVideoState({ days_of_week: [...(videoGenerationSettings.days_of_week || []), weekDay] });
                      } else {
                        if (videoGenerationSettings.days_of_week) {
                          updateVideoState({ days_of_week: _.filter(videoGenerationSettings.days_of_week, wd => wd !== weekDay) });
                        }
                      }
                    }}
                  />
                  <Text style={{ paddingLeft: "5px" }}>
                    {dayOfWeekAsString(weekDay)}
                  </Text>
                </label>
              );
            })}
          </div>

          <Row>
            <Column>
              <Text weight="bold">{"Time of day"}</Text>
            </Column>
          </Row>
          <Row>
            <Column xs={12} lg={6}>
              <DropdownFormik
                name="start_time"
                placeholder="Start Time"
                onChange={value => updateVideoState({ start_time: value })}
                defaultValue={videoGenerationSettings.start_time}
                items={times.map(time => ({
                  text: time,
                  value: time
                }))}
              />
            </Column>
            <Column xs={12} lg={6}>
              <DropdownFormik
                name="end_time"
                onChange={value => updateVideoState({ end_time: value })}
                placeholder="End Time"
                defaultValue={videoGenerationSettings.end_time}
                items={times.map(time => ({
                  text: time,
                  value: time
                }))}
              />
            </Column>
          </Row>

          <Row>
            <Column>
              <Text weight="bold">{"Resize"}</Text>
            </Column>
          </Row>
          <Row>
            <Column xs={12} lg={6}>
              <Text >{"Width"}</Text>
              <TextInput
                onChange={e => {
                  updateVideoState({
                    resize: {
                      height: videoGenerationSettings.resize?.height || defaultHeight,
                      width: parseInt(e.currentTarget.value, 10)
                    }
                  });
                }}
                name="video_resize_width"
                value={videoGenerationSettings.resize?.width || defaultWidth}
                type="number"
              />
            </Column>
            <Column xs={12} lg={6}>
              <Text >{"Height"}</Text>
              <TextInput
                onChange={e => {
                  updateVideoState({
                    resize: {
                      width: videoGenerationSettings.resize?.width || defaultWidth,
                      height: parseInt(e.currentTarget.value, 10)
                    }
                  });
                }}
                name="video_resize_height"
                value={videoGenerationSettings.resize?.height || defaultHeight}
                type="number"
              />
            </Column>
          </Row>
          
          {/** CROP */}
          <Row>
            <Column>
              <Text weight="bold">{"Crop"}</Text>
            </Column>
            <Column>

              <Checkbox
                onChange={() => {
                  setCropEnabled(!cropEnabled);
                }}
                checked={cropEnabled}
                name={"video_crop_enabled"}
                label="Enable crop"
              />
            </Column>
          </Row>
          {cropEnabled && <Row>
            <Column xs={12} lg={6}>
              <Text >{"Width"}</Text>
              <TextInput
                onChange={e => setCrop("width", parseInt(e.currentTarget.value, 10))}
                value={videoGenerationSettings.crop?.width || defaultCropValues.width}
                name="video_crop_width"
                type="number"
                min={1}
              />
            </Column>
            <Column xs={12} lg={6}>
              <Text >{"Height"}</Text>
              <TextInput
                onChange={e => setCrop("height", parseInt(e.currentTarget.value, 10))}
                value={videoGenerationSettings.crop?.height || defaultCropValues.height}
                name="video_crop_height"
                type="number"
                min={1}
              />
            </Column>

            <Column xs={12} lg={6}>
              <Text >{"Offset Left"}</Text>
              <TextInput
                onChange={e => setCrop("offsetX", parseInt(e.currentTarget.value, 10))}
                value={videoGenerationSettings.crop?.offsetX || 0}
                name="video_crop_offsetX"
                type="number"
                min={0}
              />
            </Column>
            <Column xs={12} lg={6}>
              <Text >{"Offset Top"}</Text>
              <TextInput
                onChange={e => setCrop("offsetY", parseInt(e.currentTarget.value, 10))}
                value={videoGenerationSettings.crop?.offsetY || 0}
                name="video_crop_offsetY"
                type="number"
                min={0}
              />
            </Column>
          </Row>}

          <Column xs={12} lg={6}>
            <Text>{"Audio file"}</Text>

            <DropdownFormik
              name="audio_file"
              onChange={value => updateVideoState({
                audio: {
                  s3Bucket: audioFilesBucket,
                  s3Uri: value
                } 
              })}
              placeholder="Select audio"
              defaultValue={videoGenerationSettings.audio?.s3Uri}
              items={audioDropdownValues}
              onClear={() => {
                updateVideoState({ audio: undefined });
              }}
            />
          </Column>

          <Row>
            <Column md={10}>
              <Text fontSize="h3">Video Watermark</Text>
            </Column>
            <Column>
              <Checkbox
                onChange={() => {
                  setWatermarkEnabled(!watermarkEnabled);
                  updateVideoState({ watermark: undefined });
                }}
                checked={watermarkEnabled}
                name={"video_watermark_enabled"}
                label="enabled"/>
            </Column>
          </Row>
          {watermarkEnabled &&
        <>
          <Row alignItems="flex-end">
            <Column md={8}>
              <Text fontSize="p">Watermark image</Text>
              <ImageUploader
                allowedFiles={[
                  "image/png",
                  "image/jpg",
                  "image/jpeg"
                ]}
                handleUpload={async files => {
                  if (cameraLocation) {
                    if (files) {
                      try {
                        const s3Path = await uploadToS3(
                          files[0], cameraLocation, "watermarks"
                        );

                        if (s3Path) {
                          updateVideoState({
                            watermark: {
                              s3Key: s3Path,
                              position: videoGenerationSettings.watermark?.position || "topLeft"
                            }
                          });
                        }
                      } catch (error) {
                        console.error(error);
                        toast.error("Could not upload watermark");
                      }
                    }
                  }
                }}
                multiple={false}
                label="Drag & drop to upload a watermark"
              />
            </Column>
            <Column md={4}>
              <S3Image s3Url={videoGenerationSettings.watermark?.s3Key || null}/>
            </Column>
          </Row>

          <Row>
            <Column xs={12} lg={6}>
              <Text >{"Watermark Position"}</Text>

              <DropdownFormik
                placeholder="Watermark Position"
                defaultValue={videoGenerationSettings.watermark?.position || "topLeft"}
                onChange={value => updateVideoState({
                  watermark: {
                    position: value,
                    s3Key: videoGenerationSettings.watermark?.s3Key || ""
                  }
                })}
                items={[
                  {
                    text: "Bottom Left",
                    value: "bottomLeft"
                  },
                  {
                    text: "Bottom Right",
                    value: "bottomRight"
                  },
                  {
                    text: "Top Left",
                    value: "topLeft"
                  },
                  {
                    text: "Top Right",
                    value: "topRight"
                  }
                ]}
                name="video_watermark_position" />
            </Column>
          </Row>
        </>
          }
          <>
            {uploadPath &&
            <Row alignItems="flex-end">
              <Column md={10}>
                <Text fontSize="h3">Bumpers</Text>
              </Column>
              <Column md={10}>
                <Text fontSize="p">Prepend</Text>
              </Column>
              <Column md={10}>
                <S3Dropzone
                  // @ts-ignore
                  ref={bumperPrependRef}
                  defaultValue={videoGenerationSettings.bumper?.prepend}
                  uploadPath={`${uploadPath}/bumper`}
                  allowedFiles={["video/mp4"]}
                  label="Drag & drop to upload a bumper to prepend the video"
                />
              </Column>

              <Column md={10}>
                <Text fontSize="p">Append</Text>
              </Column>
              <Column md={10}>
                <S3Dropzone
                  // @ts-ignore
                  ref={bumperAppendRef}
                  defaultValue={videoGenerationSettings.bumper?.append}
                  uploadPath={`${uploadPath}/bumper`}
                  allowedFiles={["video/mp4"]}
                  label="Drag & drop to upload a bumper to append the video"
                />
              </Column>
            </Row>
            }
          </>

        </>
      )}
    </>

  );
});

CameraLocationVideoSettings.displayName = "CameraLocationVideoSettings";