import React, {
  forwardRef,
  useRef,
  useState,
  useEffect,
  Children,
  cloneElement,
  useCallback,
  useImperativeHandle
} from "react";
import {
  IPoint, getRelativePosition, getDistanceBetweenPoints, rangeBind, getMidpoint, invert
} from "./utils/helper-functions";

const OVER_TRANSFORMATION_TOLERANCE = 0;
const DOUBLE_TAP_THRESHOLD = 200;
const MOUSE_WHEEL_ZOOM_IN_AMOUNT = 1.025; // closer to 1 is slower
const MOUSE_WHEEL_ZOOM_OUT_AMOUNT = 0.975; // closer to 1 is slower

interface IClientPosition {
  clientX: number;
  clientY: number;
}

export interface ITransform {
  left: number;
  top: number;
  scale: number;
  posTop: number;
  posLeft: number;
}

interface IProps {
  children?: React.ReactNode;
  initialTop?: number;
  initialLeft?: number;
  initialScale?: number | string;
  minScale?: number | string;
  maxScale?: number;
  zoomButtons?: boolean;
  offsetTop?: number;
  height?: number;
  width?: number;
  onZoom?: (zoom: number) => void;
  hasZoomed: (zoomed: boolean) => void;
  maxDimensions: { height: number; width: number };
  isOverlayActive?: boolean;
  handleMouseMove: () => void;
  handleToggleCompare: () => void;
  onTransform: (args: ITransform) => void;
}

/**
 * These are the imperative methods that will be exposed to the parent
 * when using forwardRef.
 */
export interface ImagePinchZoomPanHandle {
  zoomIn: (amount: number, midpoint?: IPoint) => void;
  zoomOut: (amount: number, midpoint?: IPoint) => void;
  scale: number;
  autofitScale: number;
}

/**
 * ImagePinchZoomPan component allows for pinch, zoom, and pan functionality on an image.
 * It uses forwardRef to expose imperative methods to the parent component.
 */
const ImagePinchZoomPan = forwardRef<ImagePinchZoomPanHandle, IProps>(({
  children,
  initialLeft = 0,
  initialScale = "auto",
  minScale = "auto",
  maxScale = 1,
  height = 100, // default if not provided
  width = 100, // default if not provided
  onZoom,
  hasZoomed,
  maxDimensions,
  offsetTop = 50,
  handleMouseMove,
  onTransform
},
ref) => {
  // ----------------------------------------------
  // Refs for DOM and ephemeral values
  // ----------------------------------------------
  const imageRef = useRef<HTMLElement | null>(null);
  const containerRef = useRef<HTMLElement | null>(null);
  const animationRef = useRef<number | null>(null);
  // ephemeral “instance” refs:
  const mouseDownRef = useRef<boolean>(false);
  const lastPanPointerPositionRef = useRef<IPoint | null>(null);
  const lastPointerUpTimeStampRef = useRef<number | null>(null);
  const lastPinchLengthRef = useRef<number>(0);

  const lastPinchMidpointRef = useRef<IPoint>({
    x: 0,
    y: 0 
  });

  const lastUnZoomedNegativeSpaceRef = useRef<{ height: number; width: number }>({
    height: 0,
    width: 0 
  });

  const hasZoomedRef = useRef<boolean>(false);
  const minScaleRef = useRef<number>(1);
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  // ----------------------------------------------
  // State 
  // ----------------------------------------------
  const [scale, setScale] = useState<number>(1);
  const [left, setLeft] = useState<number>(0);
  const [top, setTop] = useState<number>(0);
  const [posTop, setPosTop] = useState<number>(0);
  const [posLeft, setPosLeft] = useState<number>(0);
  const [autofitScale, setAutofitScale] = useState<number>(1);

  // ----------------------------------------------
  // Lifecycle
  // ----------------------------------------------
  // Unmounting logic
  useEffect(() => {
    const currentAnimationRef = animationRef.current; // Copy to a local variable
    const currentImageRef = imageRef.current; // Copy to a local variable

    return () => {
      if (currentAnimationRef) {
        cancelAnimationFrame(currentAnimationRef);
      }

      // Pass final transform back
      onTransform({
        left,
        top,
        scale,
        posTop,
        posLeft
      });
      // Remove global mousemove listener
      window.removeEventListener("mousemove", handleMouseMove);
      // Remove touchmove listener if the ref is still there
      currentImageRef?.removeEventListener("touchmove", handleTouchMovePassive, { capture: false } as EventListenerOptions);

      // Clear any pending timeouts
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // run only once

  // Mounting logic
  useEffect(() => {
    // Add global mousemove listener
    window.addEventListener("mousemove", handleMouseMove);

    if (imageRef.current) {
      // Add passive touchmove
      imageRef.current.addEventListener(
        "touchmove", handleTouchMovePassive, { passive: false }
      );

      // container is parent's parent
      if (imageRef.current.parentNode?.parentNode) {
        containerRef.current = imageRef.current.parentNode.parentNode as HTMLElement;
      }
    }

    // Ensure constraints, then transform to props
    ensureConstraints();
    transformToProps();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageRef.current]);

  // On update - if the max dimensions change, ensure constraints
  const prevMaxDimensionsRef = useRef(maxDimensions);

  useEffect(() => {
    if (
      prevMaxDimensionsRef.current.height !== maxDimensions.height ||
      prevMaxDimensionsRef.current.width !== maxDimensions.width
    ) {
      // Wait a bit, then re-check
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        ensureConstraints();
        transformToProps();
      }, 100);
    }

    prevMaxDimensionsRef.current = maxDimensions;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxDimensions]);

  /**
   * Calculates the scale needed to fit the image within the specified dimensions.
   * @param fit - Determines whether to fit by width, height, or both.
   * @returns The calculated autofit scale.
   */
  const calculateAutofitScale = useCallback((fit: "width" | "height" | "both" = "both") => {
    const img = imageRef.current as HTMLElement;

    if (!img) return 1;

    let offsetWidth = 0;
    let offsetHeight = 0;

    if (img.offsetWidth > 0 && img.offsetHeight > 0) {
      offsetWidth = img.offsetWidth;
      offsetHeight = img.offsetHeight;
    }

    let newAutofitScale = 1;

    if (offsetWidth > 0 && offsetHeight > 0) {
      if (fit === "width") {
        newAutofitScale = width / offsetWidth;
      } else if (fit === "height") {
        newAutofitScale = height / offsetHeight;
      } else if (fit === "both") {
        // The old logic tried to fit both dims
        if (offsetWidth > 0) {
          newAutofitScale = Math.min(width / offsetWidth, newAutofitScale);
        }

        if (offsetHeight > 0) {
          newAutofitScale = Math.min(height / offsetHeight, newAutofitScale);
        }
      }
    }

    setAutofitScale(newAutofitScale);

    return newAutofitScale;
  },
  [width, height]);

  /**
   * Handles the pointer down event and sets the initial position for panning.
   * @param clientPosition - The position of the pointer when the event occurred.
   */
  const pointerDown = useCallback((clientPosition: IClientPosition) => {
    handleMouseMove();
    const container = containerRef.current;

    if (!container) return;

    lastPanPointerPositionRef.current = getRelativePosition(clientPosition,
      container);
  },
  [handleMouseMove]);

  /**
   * Initializes the pinch gesture by recording the distance between two touch points.
   * @param touches - The list of touch points involved in the pinch gesture.
   */
  const pinchStart = useCallback((touches: React.TouchList) => {
    const container = containerRef.current;

    if (!container) return;
    const pointA = getRelativePosition(touches[0], container);
    const pointB = getRelativePosition(touches[1], container);

    lastPinchLengthRef.current = getDistanceBetweenPoints(pointA, pointB);
  }, []);

  /**
   * Centers the image based on the current scale and updates the position state.
   * @param localScale - The scale to use for centering the image.
   * @returns The new position of the image or undefined if the image dimensions are not available.
   */
  const calculateCenterImage = useCallback((localScale: number): { top: number; left: number } | undefined => {
    const img = imageRef.current as HTMLElement;

    if (img?.clientHeight && img?.clientWidth) {
      const newPosTop = Math.max(0,
        (height - img.clientHeight * localScale) / 2);

      const newPosLeft = Math.max(0,
        (width - img.clientWidth * localScale) / 2);

      setPosTop(newPosTop);
      setPosLeft(newPosLeft);

      return {
        top: newPosTop,
        left: newPosLeft
      };
    }

    return undefined;
  },
  [height, width]);

  /**
   * Calculates the negative space around the image based on the current scale.
   * @param localScale - The scale to use for the calculation (defaults to current scale).
   * @returns An object containing the width and height of the negative space.
   */
  const calculateNegativeSpace = useCallback((localScale = scale) => {
    const img = imageRef.current as HTMLElement;

    if (!img) {
      return {
        width: 0,
        height: 0 
      };
    }
    const w = width - localScale * img.offsetWidth;
    const h = height - localScale * img.offsetHeight;

    return {
      width: w,
      height: h 
    };
  },
  [
    width,
    height,
    scale
  ]);
  
  /**
   * Validates and constrains the transformation values (top, left, scale) based on defined limits.
   * @param tTop - The requested top position.
   * @param tLeft - The requested left position.
   * @param tScale - The requested scale.
   * @param tolerance - The tolerance for scaling.
   * @param minScaleVal - The minimum scale value.
   * @returns The validated transformation object.
   */
  const getValidTransform = useCallback((
    tTop: number,
    tLeft: number,
    tScale: number,
    tolerance: number,
    minScaleVal: number
  ) => {
    const transform = {
      top: tTop || 0,
      left: tLeft || 0,
      scale: tScale || 1
    };

    const lowerBoundFactor = 1.0 - tolerance;
    const upperBoundFactor = 1.0 + tolerance;

    transform.scale = rangeBind(
      minScaleVal * lowerBoundFactor,
      maxScale * upperBoundFactor,
      transform.scale
    );

    // get how much the scaled image overflows
    const negativeSpace = calculateNegativeSpace(tScale);

    const overflow = {
      height: Math.max(0, invert(negativeSpace.height)),
      width: Math.max(0, invert(negativeSpace.width))
    };

    const upperBound = overflow.height * upperBoundFactor - overflow.height;
    const lowerBound = invert(overflow.height) * upperBoundFactor;

    // top
    transform.top = rangeBind(
      lowerBound,
      upperBound,
      tTop
    );

    // left
    transform.left = rangeBind(
      invert(overflow.width) * upperBoundFactor,
      overflow.width * upperBoundFactor - overflow.width,
      tLeft
    );

    return transform;
  },
  [maxScale, calculateNegativeSpace]);

  /**
   * Applies the transformation to the image based on the requested values and updates the state.
   * @param requestedTop - The requested top position.
   * @param requestedLeft - The requested left position.
   * @param requestedScale - The requested scale.
   * @param tolerance - The tolerance for scaling.
   * @param minScaleVal - The minimum scale value.
   */
  const applyTransform = useCallback((
    requestedTop: number,
    requestedLeft: number,
    requestedScale: number,
    tolerance: number,
    minScaleVal: number
  ) => {
    const {
      top: newTop, left: newLeft, scale: newScale 
    } = getValidTransform(
      requestedTop,
      requestedLeft,
      requestedScale,
      tolerance,
      minScaleVal
    );

    if (newScale === scale && newTop === top && newLeft === left) {
      calculateCenterImage(newScale);

      return;
    }
    
    setTop(newTop);
    setLeft(newLeft);
    setScale(newScale);

    // after setting, call onTransform
    onTransform({
      left: newLeft,
      top: newTop,
      scale: newScale,
      posTop,
      posLeft
    });
    calculateCenterImage(newScale);
  },
  [
    getValidTransform,
    scale,
    top,
    left,
    onTransform,
    posTop,
    posLeft,
    calculateCenterImage
  ]);

  /**
   * Calculates the top offset needed to fit the image within the specified height.
   * @returns The calculated top offset.
   */
  const calculateTopOffsetForScaleToFit = useCallback(() => {
    const fitScale = calculateAutofitScale("width");
    const img = imageRef.current as HTMLElement;

    if (!img) return 0;
    const scaledHeight = img.offsetHeight * fitScale;
    const autoOffset = (height - scaledHeight) / 2;
    const offsetAsPercentageOfHeight = offsetTop / 100;
    const offsetInPixels = (height - scaledHeight) * offsetAsPercentageOfHeight;
    
    if (offsetInPixels !== undefined) {
      return offsetInPixels;
    }

    return autoOffset;
  }, [
    height,
    calculateAutofitScale,
    offsetTop
  ]);
  
  /**
   * Ensures that the transformation is valid and fits within the defined constraints.
   * @param scaleToFit - Indicates whether to scale to fit.
   */
  const ensureValidTransform = useCallback(({ scaleToFit }: { scaleToFit: boolean }) => {
    const minVal = scaleToFit
      ? calculateAutofitScale("width")
      : calculateAutofitScale("both");

    const newTop = scaleToFit ? calculateTopOffsetForScaleToFit() : top;

    applyTransform(
      newTop, left, scale, 0, minVal
    );
  },
  [
    calculateAutofitScale,
    calculateTopOffsetForScaleToFit,
    top,
    applyTransform,
    left,
    scale
  ]);

  /**
   * Applies constraints to the image based on the minimum scale and current dimensions.
   */
  const applyConstraints = useCallback(() => {
    let minVal: number;

    if (typeof minScale === "string") {
      minVal = calculateAutofitScale("width");
    } else {
      minVal = minScale;
    }

    if (minScaleRef.current !== minVal) {
      minScaleRef.current = minVal;
      ensureValidTransform({ scaleToFit: true });
    } else {
      calculateCenterImage(scale);
    }

    lastUnZoomedNegativeSpaceRef.current = calculateNegativeSpace(1);
  }, [
    minScale,
    calculateNegativeSpace,
    calculateAutofitScale,
    ensureValidTransform,
    calculateCenterImage,
    scale
  ]);
  
  /**
   * Ensures that the constraints are applied based on the current image dimensions.
   */
  const ensureConstraints = useCallback(() => {
    const img = imageRef.current as HTMLElement;

    if (img) {
      if (img.offsetWidth && img.offsetHeight) {
        const negativeSpace = calculateNegativeSpace(1);

        if (
          !lastUnZoomedNegativeSpaceRef.current ||
          negativeSpace.height !== lastUnZoomedNegativeSpaceRef.current.height ||
          negativeSpace.width !== lastUnZoomedNegativeSpaceRef.current.width
        ) {
          // update constraints
          
          applyConstraints();
        }
      }
    }
  }, [applyConstraints, calculateNegativeSpace]);

  /**
   * Zooms the image to a requested scale and adjusts the position based on the midpoint.
   * @param requestedScale - The scale to zoom to.
   * @param midpoint - The point around which to zoom.
   * @param tolerance - The tolerance for scaling.
   */
  const zoom = useCallback((
    requestedScale = 0.5,
    midpoint: IPoint,
    tolerance: number
  ) => {
    const validTransform = getValidTransform(
      0,
      0,
      requestedScale,
      tolerance,
      calculateAutofitScale("both")
    );

    const finalScale = validTransform.scale;

    if (
      finalScale !== calculateAutofitScale("width") &&
        hasZoomedRef.current === false
    ) {
      hasZoomed(true);
      hasZoomedRef.current = true;
    }

    // how much the scale changed as a ratio
    const incrementalScalePercentage = (scale - finalScale) / scale;
    const translateY = (midpoint.y - top) * incrementalScalePercentage;
    const translateX = (midpoint.x - left) * incrementalScalePercentage;
    const newTop = top + translateY;
    const newLeft = left + translateX;

    if (onZoom) {
      onZoom(finalScale);
    }

    applyTransform(
      newTop,
      newLeft,
      finalScale,
      tolerance,
      calculateAutofitScale("both")
    );
  },
  [
    getValidTransform,
    calculateAutofitScale,
    scale,
    top,
    left,
    onZoom,
    applyTransform,
    hasZoomed
  ]);

  /**
   * Zooms in the image by a specified amount around a midpoint.
   * @param amount - The zoom factor.
   * @param midpoint - The point around which to zoom (optional).
   */
  const zoomIn = useCallback((amount: number, midpoint?: IPoint) => {
    const container = containerRef.current;

    if (!container) return;

    midpoint = midpoint || {
      x: container.offsetWidth / 2,
      y: container.offsetHeight / 2
    };
    const newScale = scale * amount;

    zoom(
      newScale, midpoint, 0
    );
  },
  [scale, zoom]);

  /**
   * Zooms out the image by a specified amount around a midpoint.
   * @param amount - The zoom factor.
   * @param midpoint - The point around which to zoom (optional).
   */
  const zoomOut = useCallback((amount: number, midpoint?: IPoint) => {
    const container = containerRef.current;

    if (!container) return;

    midpoint = midpoint || {
      x: container.offsetWidth / 2,
      y: container.offsetHeight / 2
    };
    const newScale = scale * amount;

    zoom(
      newScale, midpoint, 0
    );
  },
  [scale, zoom]);

  /**
   * Handles the pinch gesture by calculating the new scale based on the distance between touch points.
   * @param touches - The list of touch points involved in the pinch gesture.
   */
  const pinchChange = useCallback((touches: React.TouchList) => {
    const container = containerRef.current;

    if (!container) return;
    const pointA = getRelativePosition(touches[0], container);
    const pointB = getRelativePosition(touches[1], container);
    const length = getDistanceBetweenPoints(pointA, pointB);
    let newScale = scale;

    if (scale) {
      newScale = (scale * length) / lastPinchLengthRef.current;
    }
    const midpoint = getMidpoint(pointA, pointB);

    zoom(
      newScale, midpoint, OVER_TRANSFORMATION_TOLERANCE
    );
    lastPinchMidpointRef.current = midpoint;
    lastPinchLengthRef.current = length;
  },
  [scale, zoom]);

  /**
   * Moves the image to a requested position based on the top and left values.
   * @param requestedTop - The requested top position.
   * @param requestedLeft - The requested left position.
   * @param tolerance - The tolerance for the movement.
   */
  const move = useCallback((
    requestedTop: number, requestedLeft: number, tolerance: number
  ) => {
    applyTransform(
      requestedTop,
      requestedLeft,
      scale,
      tolerance,
      calculateAutofitScale("both")
    );
  },
  [
    applyTransform,
    scale,
    calculateAutofitScale
  ]);
  
  /**
   * Handles the panning of the image based on the pointer's position.
   * @param pointerClientPosition - The position of the pointer.
   * @returns The direction of the pan (1 for down, -1 for up, 0 for none).
   */
  const pan = useCallback((pointerClientPosition: IClientPosition) => {
    const container = containerRef.current;

    if (!container) return 0;

    const pointerPosition = getRelativePosition(pointerClientPosition, container);

    if (lastPanPointerPositionRef.current) {
      const translateX = pointerPosition.x - lastPanPointerPositionRef.current.x;
      const translateY = pointerPosition.y - lastPanPointerPositionRef.current.y;
      const newTop = top + translateY;
      const newLeft = left + translateX;

      // Move
      move(
        newTop, newLeft, 0
      );
      lastPanPointerPositionRef.current = pointerPosition;

      return translateY > 0 ? 1 : translateY < 0 ? -1 : 0;
    } else {
      return 0;
    }
  },
  [
    top,
    left,
    move
  ]);

  /**
   * Transforms the initial properties of the image based on the provided props.
   */
  const transformToProps = useCallback(() => {
    let newScale: number;

    if (typeof initialScale === "string") {
      // user wants "auto" in some manner
      newScale = calculateAutofitScale("width");
    } else {
      newScale = initialScale;
    }

    const centreImage = calculateCenterImage(newScale);
    
    setLeft(initialLeft);
    setScale(newScale);

    // after setting, call onTransform
    onTransform({
      left: initialLeft,
      top,
      scale: newScale,
      posTop: centreImage?.top || posTop,
      posLeft: centreImage?.left || posLeft
    });
  }, [
    initialScale,
    posTop,
    posLeft,
    top,
    initialLeft,
    calculateAutofitScale,
    onTransform,
    calculateCenterImage
  ]);

  /**
   * Handles the pointer up event and checks for double tap to reset the image.
   * @param timeStamp - The timestamp of the pointer up event.
   */
  const pointerUp = useCallback((timeStamp: number) => {
    if (
      lastPointerUpTimeStampRef.current &&
        lastPointerUpTimeStampRef.current + DOUBLE_TAP_THRESHOLD > timeStamp
    ) {
      // double tap => reset
      transformToProps();
    }

    lastPointerUpTimeStampRef.current = timeStamp;
  },
  [transformToProps]);

  /**
   * Handles the touch start event and initializes pinch or pan gestures.
   * @param event - The touch event.
   */
  const handleTouchStart = useCallback((event: React.TouchEvent) => {
    if (animationRef.current) {
      cancelAnimationFrame(animationRef.current);
    }
    const touches = event.touches;

    if (touches.length === 2) {
      pinchStart(touches);
      lastPanPointerPositionRef.current = null;
    } else if (touches.length === 1) {
      pointerDown({
        clientX: touches[0].clientX,
        clientY: touches[0].clientY
      });
    }
  }, [pinchStart, pointerDown]);

  /**
   * Handles the touch move event and applies the appropriate gesture (pinch or pan).
   * @param event - The touch event.
   */
  const handleTouchMove = useCallback((event: React.TouchEvent) => {
    const touches = event.touches;

    if (touches.length === 2) {
      //  suppress viewport scaling
      event.preventDefault();
      pinchChange(touches);
    } else if (touches.length === 1) {
      const swipingDown = pan({
        clientX: touches[0].clientX,
        clientY: touches[0].clientY
      }) > 0;

      if (top === undefined) {
        event.preventDefault();
      } else if (swipingDown && top < 0) {
        // suppress pull-down-refresh
        event.preventDefault();
      }
    }
  },
  [
    pan,
    pinchChange,
    top
  ]);

  // ----------------------------------------------
  // Passive “touchmove” callback (to prevent default)
  // ----------------------------------------------
  /**
   * Handles passive touch move events to prevent default behavior.
   * @param e - The touch event.
   */
  const handleTouchMovePassive = useCallback((e: TouchEvent) => {
    // We just call the internal React-based handler
    // This replicates the old "this.handleTouchMove" with {passive:false}
    // so we can do `event.preventDefault()` if needed
    // We must wrap it in a synthetic event approach, or replicate the logic directly:
    const reactTouchEvent = {
      touches: e.touches,
      preventDefault: () => e.preventDefault()
    } as unknown as React.TouchEvent;

    handleTouchMove(reactTouchEvent);
  }, [handleTouchMove]);
  
  /**
   * Handles the touch end event and applies bounce back if needed.
   * @param event - The touch event.
   */
  const handleTouchEnd = useCallback((event: React.TouchEvent) => {
    if (event.touches && event.touches.length > 0) {
      return null;
    }

    // bounce back if needed
    ensureValidTransform({ scaleToFit: false });
    pointerUp(event.timeStamp);
    event.preventDefault();

    return null;
  }, [ensureValidTransform, pointerUp]);

  /**
   * Handles the mouse down event and initializes panning.
   * @param event - The mouse event.
   */
  const handleMouseDown = useCallback((event: React.MouseEvent) => {
    if (animationRef.current) {
      cancelAnimationFrame(animationRef.current);
    }

    mouseDownRef.current = true;

    pointerDown({
      clientX: event.clientX,
      clientY: event.clientY
    });
  }, [pointerDown]);

  /**
   * Handles the local mouse move event and applies panning if the mouse is down.
   * @param event - The mouse event.
   */
  const handleMouseMoveLocal = useCallback((event: React.MouseEvent) => {
    if (!mouseDownRef.current) {
      return;
    }

    pan({
      clientX: event.clientX,
      clientY: event.clientY
    });
  },
  [pan]);

  /**
   * Handles the mouse up event and finalizes the pointer up action.
   * @param event - The pointer event.
   */
  const handleMouseUp = useCallback((event: React.PointerEvent) => {
    pointerUp(event.timeStamp);

    if (mouseDownRef.current) {
      mouseDownRef.current = false;
    }
  }, [pointerUp]);

  /**
   * Handles the mouse wheel event for zooming in and out.
   * @param event - The wheel event.
   */
  const handleMouseWheel = useCallback((event: React.WheelEvent) => {
    if (animationRef.current) {
      cancelAnimationFrame(animationRef.current);
    }
    const container = containerRef.current;

    if (!container) return;
    const point = getRelativePosition(event, container);

    if (event.deltaY > 0) {
      // Zoom out
      if (scale > calculateAutofitScale("both")) {
        zoomOut(MOUSE_WHEEL_ZOOM_OUT_AMOUNT, point);
        event.preventDefault();
      }
    } else if (event.deltaY < 0) {
      // Zoom in
      if (scale < maxScale) {
        zoomIn(MOUSE_WHEEL_ZOOM_IN_AMOUNT, point);
        event.preventDefault();
      }
    }
  },
  [
    scale,
    maxScale,
    zoomOut,
    zoomIn,
    calculateAutofitScale
  ]);

  /**
   * Handles the image load event and ensures constraints are applied.
   */
  const handleImageLoad = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      ensureConstraints();
    }, 80);

    // check if scale is different from autofit
    if (scale !== calculateAutofitScale("width")) {
      hasZoomed(true);
      hasZoomedRef.current = true;
    } else {
      hasZoomed(false);
      hasZoomedRef.current = false;
    }
  }, [
    scale,
    calculateAutofitScale,
    ensureConstraints,
    hasZoomed
  ]);

  useImperativeHandle(ref, () => ({
    zoomIn,
    zoomOut,
    scale,
    autofitScale
  }));
  // ----------------------------------------------
  // Render
  // ----------------------------------------------
  const childElement = Children.only(children) as React.ReactElement<any>;

  return (
    <div
      style={{
        height: `${height}px`,
        width: `${width}px`,
        overflow: "hidden",
        position: "relative"
      }}
    >
      {cloneElement(childElement, {
        onDragStart: (event: React.DragEvent) => event.preventDefault(),
        onLoad: handleImageLoad,
        onMouseDown: handleMouseDown,
        onMouseMove: handleMouseMoveLocal,
        onMouseUp: handleMouseUp,
        onTouchEnd: handleTouchEnd,
        onTouchStart: handleTouchStart,
        onWheel: handleMouseWheel,
        ref: imageRef
      })}
    </div>
  );
});

export default ImagePinchZoomPan;
