import React from "react";
import { IImage } from "src/types/store/images";
import getImageByType from "src/lib/getImageByType";
import { IThumbnail, ImageType } from "../../types/store/images";
import {
  ImagePinchZoomPan, RangeSlider, DisplayModeButtons
} from "..";
import styled from "styled-components";
import { styles } from "src/lib/styles";
import WipeControls from "../wipe-controls";
import { ICameraLocation } from "../../store/camera-locations/camera-locations.api";
import { getContainerHeight } from "./utils/getContainerHeight";
import { ActiveLine } from "./compare-active-line";
import { DraggableData, DraggableEvent } from "react-draggable";
import { TimelapseImage } from "./TimelapseImage";
import { useWindowSize } from "../../hooks/useWindowSize";
import { UserVideoGenerationForm } from "../user-video-generation-form";
import { ImagePinchZoomPanHandle } from "../image-pinch-zoom-pan/ImagePinchZoomPan";

interface ImageViewPanelProps {
  image: IImage | null;
  compareImage?: IImage | null;
  compareDragged?: IImage | null;
  activeCompareImage?: "compare-a" | "compare-b";
  dragged: IImage | null;
  zoomed: boolean;
  activeEndDate?: moment.Moment
  activeStartDate?: moment.Moment
  cameraLocation?: ICameraLocation | null
  handleZoom: (didZoom: boolean) => void;
  isOverlayActive: boolean;
  isPlaying: boolean;
  handleMouseMove: () => void;
  handleToggleCompare: () => void;
  isCompareActive: boolean;
  wait: number;
  compareMode: "vertical" | "horizontal" | "overlay" | "difference";
  transform: ITransform;
  allowKeys?: (allow: boolean) => void;
}

export interface ITransform {
  left: number;
  top: number;
  scale: number | string;
  posTop: number;
  posLeft: number;
}

interface IImageSource {
  zoomed: IThumbnail | null;
  initial: IThumbnail | null;
}

export interface IDimensions {
  height: number;
  width: number;
}

interface IPosition {
  x: number;
  y: number;
}

/**
 * ImageViewPanel
 * 
 * Responsible for displaying an interactive image. Takes a base image,
 * and optionally a secondary image to compare. This also 
 * @param props ImageViewPanelProps
 */
export const ImageViewPanel: React.FC<ImageViewPanelProps> = props => {
  // Primary Image
  const [imageSources, setImageSources] = React.useState<IImageSource>({
    zoomed: null,
    initial: null
  });
  
  // Secondary (compare) Image
  const [compareImageSources, setCompareImageSources] = React.useState<IImageSource>({
    zoomed: null,
    initial: null
  });
  
  // Setup thumbnails when dragging the scrubber
  const [draggedUri, setDraggedUri] = React.useState<string>("");
  const [compareDraggedUri, setCompareDraggedUri] = React.useState<string>("");
  // Triggers load of high res image
  const [shouldLoadHighRes, setShouldLoadHighRes] = React.useState<boolean>(false);
  const [showingHighRes, setShowHighRes] = React.useState<boolean>(false);
  // Sets max dimensions - we must wait for these to load the image at the right zoom
  const [maxDimensions, setMaxDimensions] = React.useState<IDimensions | null>(null);
  // Overlay amount for compare mode
  const [overlayAmount, setOverlayAmount] = React.useState<number>(0.5);
  // Sets the device based off responsive window width
  const [device, setDevice] = React.useState<ImageType>("tablet");
  // Sets whether to display the video generation panel
  const [userVideoGenerationPanel, setUserVideoGenerationPanel] = React.useState<boolean>(false);

  // Sets where the slider is for compare mode
  const [swipePos, setSwipePos] = React.useState<IPosition>({
    x: window.innerWidth / 2 + 20,
    y: 0
  });

  // Store the current zoom / position of the image
  const [transform, setTransform] = React.useState<ITransform>({
    left: 0,
    top: 0,
    scale: "auto",
    posTop: 0,
    posLeft: 0
  });

  // Watch window size
  const windowSize = useWindowSize();

  // Set the breakpoints
  React.useEffect(() => {
    if (windowSize.width <= 800) {
      setDevice("mobile");
    } else if (windowSize.width <= 1100) {
      setDevice("tablet");
    } else {
      setDevice("desktop-sd");
    }
  }, [windowSize]);

  // Set initial swipe position (for A/B comparison)
  React.useEffect(() => {
    setSwipePos({
      x: window.innerWidth / 2 + 20,
      y: getContainerHeight() / 2
    });
  }, [props.compareMode]);

  // Set the dragged thumbnail when scrubbing
  const setDragged = React.useCallback(async (
    dragged: IImage | null | undefined,
    compare: boolean
  ) => {
    if (dragged) {
      const thumb = await getImageByType("thumbnail", dragged.thumbnails);

      if (thumb.s3_url) {
        if (compare) {
          setCompareDraggedUri(thumb.s3_url);
        } else {
          setDraggedUri(thumb.s3_url);
        }
      }
    } else {
      setCompareDraggedUri("");
      setDraggedUri("");
    }
  }, []);
  
  // Load the image sources
  const handleSetImageSources = React.useCallback(async (image: IImage, compare: boolean) => {
    let imageSources: {
      initial: IThumbnail | null;
      zoomed: IThumbnail | null;
      thumbnail: IThumbnail | null;
    } = {
      initial: null,
      thumbnail: null,
      zoomed: null
    };

    if (image) {
      let maxDimensions = {
        height: image.height,
        width: image.width
      };

      imageSources = {
        ...imageSources,
        initial: await getImageByType(device, image.thumbnails),
        zoomed: await getImageByType("desktop-max", image.thumbnails)
      };

      if (imageSources.zoomed) {
        maxDimensions = {
          height: imageSources.zoomed.height,
          width: imageSources.zoomed.width
        };
      }

      imageSources.thumbnail = await getImageByType("thumbnail", image.thumbnails);

      if (compare) {
        setCompareImageSources(imageSources);
      } else {
        setImageSources(imageSources);
      }
      
      setMaxDimensions(maxDimensions);
    }
  }, [device]);

  // When the image changes
  React.useEffect(() => {
    if (props.image) {
      setShowHighRes(false);
      handleSetImageSources(props.image, false);
    }
  }, [props.image, handleSetImageSources]);
  
  // When the compare image changes
  React.useEffect(() => {
    if (props.compareImage) {
      setShowHighRes(false);
      handleSetImageSources(props.compareImage, true);
    }
  }, [props.compareImage, handleSetImageSources]);
  
  // When dragging the scrubber
  React.useEffect(() => {
    setDragged(props.dragged, false);
  }, [props.dragged, setDragged]);
  
  // When dragging the scrubber on the compare image
  React.useEffect(() => {
    setDragged(props.compareDragged, false);
  }, [props.compareDragged, setDragged]);
 
  const {
    zoomed, dragged, compareDragged, isPlaying
  } = props;

  // Get the current image URL based on whether dragging, zoomed or compare
  const getImageUrl = React.useCallback((compare: boolean) => {
    const image = compare ? compareImageSources : imageSources;

    // If compare-b is being dragged, overlay the dragged uri
    if (compare && compareDragged) {
      return compareDraggedUri;
    }

    // If compare-a is being dragged, overlay the dragged uri
    if (!compare && dragged) {
      return draggedUri;
    }

    // Load the high resolution image
    if (showingHighRes && image.zoomed) {
      setShouldLoadHighRes(false);

      return image.zoomed.s3_url;
    }

    // If zoomed, load the high resolution image
    if (zoomed && image.zoomed && !isPlaying) {
      return image.zoomed.s3_url;
    // Otherwise load the initial resolution image
    } else if (image.initial) {
      // But first start the timer to load the high resolution image
      setShouldLoadHighRes(true);

      return image.initial.s3_url;
    } else {
      return "";
    }
  }, [
    compareDragged,
    compareDraggedUri,
    compareImageSources,
    dragged,
    draggedUri,
    imageSources,
    isPlaying,
    showingHighRes,
    zoomed
  ]);
  
  React.useEffect(() => {
    const timer = setTimeout(() => {
      setShowHighRes(true);
    }, shouldLoadHighRes ? 3000 : null);

    return () => clearTimeout(timer);
  }, [shouldLoadHighRes]);

  // Change the swipe position of the compare slider
  const handleDragCompareSlider = React.useCallback((e: DraggableEvent, data: DraggableData) => {
    if (props.compareMode === "vertical") {
      setSwipePos({
        x: data.x + 20,
        y: 0
      });
    } else {
      setSwipePos({
        x: 0,
        y: data.y + 20
      });
    }
  }, [props.compareMode]);

  const pinchZoom = React.useRef<ImagePinchZoomPanHandle>(null);
  
  return (
    <div style={{ overflow: "hidden" }}>
      <div style={{ opacity: props.image?.status === "suspend" ? 0.5 : 1 }}>
        {maxDimensions && (
          <ViewerPanel>
            {props.isCompareActive && (
              <ActiveLine 
                compareMode={props.compareMode}
                activeCompareImage={props.activeCompareImage}
                swipePos={swipePos} />
            )}
            <ImagePinchZoomPan
              ref={pinchZoom}
              offsetTop={props.cameraLocation?.offset_top}
              height={getContainerHeight()}
              width={window.innerWidth}
              maxDimensions={maxDimensions}
              hasZoomed={props.handleZoom}
              isOverlayActive={props.isOverlayActive}
              handleMouseMove={props.handleMouseMove}
              handleToggleCompare={props.handleToggleCompare}
              onTransform={values => setTransform(values)}
              initialLeft={ transform ? transform.left : 0 }
              initialTop={ transform ? transform.top : 0 }
              initialScale={ transform ? transform.scale : "auto" }
            >
              <div
                id="image-view-panel"
                style={{
                  cursor: "pointer",
                  height: maxDimensions.height,
                  left: transform.posLeft + "px",
                  opacity:
                    props.compareMode === "overlay" ? overlayAmount : 1,
                  position: "absolute",
                  top: transform.posTop + "px",
                  transform: `translate(${transform.left}px, ${
                    transform.top
                  }px) scale(${transform.scale})`,
                  transformOrigin: "0 0",
                  width: maxDimensions.width,
                  zIndex: props.compareMode === "overlay" ? 2 : 0
                }}
              >
                <TimelapseImage 
                  compare={false}  
                  maxDimensions={maxDimensions}
                  transform={transform}
                  getImageUrl={getImageUrl}
                />
                
              </div>
            </ImagePinchZoomPan>
            {props.isCompareActive && (
              <CompareImageContainer
                style={{
                  height:
                    props.compareMode === "horizontal"
                      ? swipePos.y
                        ? `${swipePos.y}px`
                        : `${getContainerHeight() / 2}px`
                      : "100%",
                  mixBlendMode:
                    props.compareMode === "difference"
                      ? "difference"
                      : "normal",
                  width:
                    props.compareMode === "vertical"
                      ? `${swipePos.x}px`
                      : "100vw",
                  zIndex: props.compareMode === "overlay" ? 0 : 2
                }}
              >
                <TimelapseImage 
                  compare={true}  
                  maxDimensions={maxDimensions}
                  transform={transform}
                  getImageUrl={getImageUrl}
                />
              </CompareImageContainer>
            )}
            {userVideoGenerationPanel && (
              <UserVideoGenerationForm 
                activeEndDate={props.activeEndDate}  
                activeStartDate={props.activeStartDate}  
                cameraLocation={props.cameraLocation}  
                closeForm={() => setUserVideoGenerationPanel(false)}
                allowKeys={props.allowKeys}
              />
            )}
            <DisplayModeButtons
              isCompareActive={props.isCompareActive}
              toggleUserVideoGenerationForm={() => setUserVideoGenerationPanel(!userVideoGenerationPanel)}
              isOverlayActive={props.isOverlayActive}
              handleToggleCompare={props.handleToggleCompare}
              zoomOut={amount => pinchZoom.current?.zoomOut(amount)}
              zoomIn={amount => pinchZoom.current?.zoomIn(amount)}
              scale={pinchZoom.current?.scale}
              minScale={pinchZoom.current?.autofitScale}
              maxScale={1}
              fullSecurity={props.cameraLocation?.site?.security_level === 1 || props.cameraLocation?.site?.customer?.security_level === 1}
            />
          </ViewerPanel>
        )}
        <div>
          {props.isCompareActive && (
            <>
              {(props.compareMode === "vertical" ||
                props.compareMode === "horizontal") && (
                <WipeControls
                  containerHeight={getContainerHeight()}
                  handleDrag={handleDragCompareSlider}
                  compareMode={props.compareMode}
                  isOverlayActive={props.isOverlayActive}
                />
              )}
              {props.compareMode === "overlay" && (
                <RangeSlider
                  min={0}
                  max={1}
                  step={0.05}
                  value={overlayAmount}
                  onChange={value => setOverlayAmount(value)}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const ViewerPanel = styled.div`
  user-select: none;
`;

const CompareImageContainer = styled.div`
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  top: 0px;
  z-index: 2;
  background-color: ${styles.primaryDarkColor};
`;
