import React from "react";
import styled from "styled-components";
import { Icon } from "../../components";
import { styles } from "../../lib/styles";
import { IImage, ImageType } from "src/types/store/images";
import getImageByType from "src/lib/getImageByType";
import { IUser } from "src/types/store/users";

const SharingActionsContainer = styled.div`
  max-height: 40px;
  max-width: 100%;
  display: flex;

  > * {
    padding-right: 10px;
  }

  .action-button {
    display: inline-block;
    padding-right: 10px;
  }

  @media (max-width: ${styles.mobileBreakPoint}) {
    display: none;
  }
`;

interface ISharingActions {
  currentImage?: number;
  auth: { admin: boolean; authenticated: boolean };
  handleSuspendImage: () => void;
  handleHiddenPlayImage: () => void;
  image?: IImage;
  user: IUser;
  fullSecurity: boolean;
}

/**
 * Check if a URL points to a valid image by creating an off-DOM <img>.
 */
async function isValidImageUrl(url: string): Promise<boolean> {
  return new Promise(resolve => {
    const img = new Image();

    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);
    img.src = url;
  });
}

const SharingActions: React.FC<ISharingActions> = ({
  auth,
  image,
  user,
  fullSecurity,
  handleSuspendImage,
  handleHiddenPlayImage
}) => {
  /**
   * Fetch a valid image URL only when the user clicks "download".
   */
  const handleDownloadClick = async (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault(); // Prevent the anchor from navigating immediately

    if (!image) return;

    // Admin can see "original" first
    const availableTypes: ImageType[] = auth.admin
      ? [
        "original",
        "desktop-max",
        "tablet"
      ]
      : ["desktop-max", "tablet"];

    let validUrl: string | undefined;

    for (const type of availableTypes) {
      const result = await getImageByType(type, image.thumbnails);

      if (result?.s3_url) {
        const valid = await isValidImageUrl(result.s3_url);

        if (valid) {
          validUrl = result.s3_url;
          break;
        }
      }
    }

    if (validUrl) {
      // Trigger a programmatic download
      const link = document.createElement("a");

      link.href = validUrl;
      link.download = ""; // or give it a name: link.download = "myImage.jpg"
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      link.remove();
    } else {
      // Optional: handle case where no valid image was found
      alert("No valid image was found for download.");
    }
  };

  return (
    <SharingActionsContainer>
      {/* Only show the download icon if user can download and there's no full security */}
      {user.can_download && !fullSecurity && (
        <a href="#!" onClick={handleDownloadClick} rel="noreferrer">
          <Icon icon="download" size={25} />
        </a>
      )}

      {/* Admin-specific controls */}
      {image && auth.admin && (
        <div>
          <div className="action-button" onClick={handleSuspendImage}>
            {image.status !== "suspend" ? (
              <Icon icon="eye-unhidden" size={25} />
            ) : (
              <Icon
                icon="eye-hidden"
                style={{ fill: styles.secondaryAccentColor }}
                size={25}
              />
            )}
          </div>

          <div
            className="action-button"
            style={{ visibility: image.status !== "suspend" ? "visible" : "hidden" }}
            onClick={handleHiddenPlayImage}
          >
            {image.status === "hidden-during-play" ? (
              <Icon
                icon="disable-video"
                size={25}
                style={{ fill: styles.secondaryAccentColor }}
              />
            ) : (
              <Icon icon="disable-video" size={25} />
            )}
          </div>
        </div>
      )}
    </SharingActionsContainer>
  );
};

export default SharingActions;
