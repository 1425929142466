import React, { useState } from "react";
import { connect } from "react-redux";
import * as Redux from "redux";
import { ThunkDispatch } from "redux-thunk";
import { AudioSettings, ICameraLocation } from "src/store/camera-locations/camera-locations.api";
import { generateVideoRequest, IVideoGenerationParams } from "src/store/videos/videos.actions";
import { IStore } from "src/types/store/store";
import styled, { keyframes } from "styled-components";

import {
  Text, Button, Box, Icon 
} from "..";
import { styles } from "../../lib/styles";
import { SelectTimes } from "./select-times";
import { SelectDays } from "./select-days";
import { SelectVideoLength } from "./select-video-length";
import { SetVideoName } from "./set-video-name";
import { NotifyEmail } from "./notify-email";
import { IVideoStore } from "../../types/store/videos";
import { audioFiles, getAudioFileDisplayName } from "../camera-location-video-settings/camera-location-video-settings";
import { SelectAudio } from "./select-audio";

interface IStoreProps {
  videos: IVideoStore;
}
interface IProvidedProps {
  activeEndDate?: moment.Moment;
  activeStartDate?: moment.Moment;
  cameraLocation?: ICameraLocation | null;
  closeForm: () => void;
  allowKeys?: (allow: boolean) => void;
}
interface IDispatchProps {
  generateVideoRequest: (cameraId: string, params: IVideoGenerationParams) => void;
}

const UserVideoGenerationForm: React.FC<IStoreProps & IDispatchProps & IProvidedProps> = props => {
  const selectedRange = React.useMemo(() => {
    const start = props.activeStartDate?.format("DD/MM/yy");
    const end = props.activeEndDate?.format("DD/MM/yy");

    if (start === end) { 
      return start;
    } else {
      return `${start} - ${end}`;
    }
  }, [props.activeStartDate, props.activeEndDate]);

  const [name, setName] = React.useState<string>("My video");
  const [emailAddress, setEmailAddress] = React.useState<string>("");
  const [videoLength, setVideoLength] = React.useState<string>("60");
  const [startTime, setStartTime] = React.useState<string>(props.cameraLocation?.video_settings?.start_time || "08:00");
  const [endTime, setEndTime] = React.useState<string>(props.cameraLocation?.video_settings?.end_time || "16:00");

  const [daysToInclude, setDaysToInclude] = React.useState<number[]>(props.cameraLocation?.video_settings?.days_of_week || [
    2, 
    3, 
    4, 
    5, 
    6
  ]);

  const [audio, setAudio] = useState<AudioSettings | undefined>(props.cameraLocation?.video_settings.audio); // defaults to audio setting from the camera location

  const audioItems = React.useMemo(() => {
    const defaultAudio = audioFiles.find(a => a?.s3Uri && a.s3Uri === props.cameraLocation?.video_settings?.audio?.s3Uri);
    
    return [
      {
        data: undefined,
        text: "No audio"
      },
      ...audioFiles.map((file, i) => ({
        data: file,
        text: (file?.s3Uri ? getAudioFileDisplayName(file.s3Uri) : `audio#${i}`) + (defaultAudio?.s3Uri === file?.s3Uri ? " (default)" : "")
      }))
    ];
  }, [props.cameraLocation?.video_settings?.audio?.s3Uri]);

  const [success, setSuccess] = React.useState<boolean>(false);
  
  React.useEffect(() => {
    if (props.videos.userVideoSuccess) {
      setSuccess(props.videos.userVideoSuccess);
    }
  }, [props.videos]);

  const handleSubmit = React.useCallback(() => {
    if (props.cameraLocation && props.activeStartDate && props.activeEndDate) {
      props.generateVideoRequest(props.cameraLocation.id.toString(), {
        start_date: props.activeStartDate.format("yyyy-MM-DD"),
        end_date: props.activeEndDate.format("yyyy-MM-DD"),
        name,
        start_time: startTime,
        end_time: endTime,
        days_of_week: daysToInclude,
        video_length: videoLength,
        notify_email: emailAddress === "" ? undefined : emailAddress,
        audio
      });
    }
  }, [
    daysToInclude,
    emailAddress,
    endTime,
    name,
    props,
    startTime,
    videoLength,
    audio
  ]);

  return (
    <FormWrapper>
      <CloseButton >
        <Icon size={20} icon="close" onClick={props.closeForm} />
      </CloseButton>
      {success ? (
        <>
          <Section>
            <Text fontSize="h2" weight="bold">
    Video queued.
            </Text>
            <Box pad={{ top: "md" }}>
              <Text>
                {emailAddress ? "Your video is being created. We'll send you an email when it's ready." : "Your video is being created. Check back in about 10 minutes when your completed video will appear in the Video tab for this camera."}
              </Text>
            </Box>
            <Box pad={{ top: "md" }}>
              <Button text="Create another" clickHandler={() => setSuccess(false)} />
            </Box>
          </Section>
        </>
      ) : (
        <>
          <Section style={{ background: "#0a121f" }}>
            <Text 
              fontSize="h2"
              weight="bold"
            >
        Export video
            </Text>
            <Box pad={{ top: "md" }}>
              <Text>
        Create a full-res timelapse video of your selected date range. Using the controls below, you can modify and preview your timelapse before exporting the video.
              </Text>
            </Box>
            <Box pad={{ top: "md" }} flexDir="row" align="center">
              <Text>
                  Select dates using the calendar
              </Text>
              <Box pad="5px">
                <Icon size={15} icon="calendar" />
              </Box>
              <Text>
                  button.
              </Text>
            </Box>
            <Box pad={{ top: "md" }}>
              <SelectedPreview>
                Currently selected: <strong>{selectedRange}</strong>
              </SelectedPreview>
            </Box>
          </Section>
          <Section>
            <Box pad={{ bottom: "lg" }}>
              <Text
                fontSize="h4" 
                weight="bold"
              >
                Customise your video
              </Text>
            </Box>
            <Box pad={{ top: "sm" }}>
              <SelectTimes 
                end={endTime}
                start={startTime}
                setEnd={setEndTime}
                setStart={setStartTime}
              />
            </Box>
            <Box pad={{ top: "sm" }}>
              <SelectDays 
                daysToInclude={daysToInclude}
                setDaysToInclude={setDaysToInclude}
              />
            </Box>
            <Box pad={{ top: "sm" }}>
              <SelectVideoLength 
                videoLength={videoLength}
                setVideoLength={setVideoLength}
              />
            </Box>
            <Box pad={{ top: "sm" }}>
              <SelectAudio
                onSelect={val => setAudio(val as AudioSettings)}
                items={audioItems}
                selected={Math.max(0, audioItems.findIndex(a => a.data && a?.data.s3Uri === audio?.s3Uri))}
              />
            </Box>
            <Box pad={{ top: "sm" }} width="100%">
              <SetVideoName
                allowKeys={props.allowKeys}
                name={name}
                setName={setName}
              />
            </Box>
          </Section>
          <Section>
            <NotifyEmail 
              allowKeys={props.allowKeys}
              emailAddress={emailAddress}
              setEmailAddress={setEmailAddress}
            />
            <Box pad={{ top: "lg" }}>
              <Button text="Create" clickHandler={handleSubmit} />
            </Box>
          </Section>
        </>
      )}
    </FormWrapper>
  );
};

const mapStateToProps = (state: IStore): IStoreProps => ({ videos: state.videos });

const mapDispatchToProps = (dispatch: ThunkDispatch<IStore, void, Redux.Action>): IDispatchProps => {
  return { generateVideoRequest: (cameraId, params) => dispatch(generateVideoRequest(cameraId, params)) };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserVideoGenerationForm);

const slideInFromRight = keyframes`
  0% {
    right: -100vw;
    opacity: 0;
  }
  100% {
    right: 0vw;
    opacity: 1;
  }
`;

const FormWrapper = styled.div`
  position: absolute;
  top: 0px;
  right: 0px; 
  bottom: 0px;
  min-width: 300px;
  max-width: 500px;
  width: 50vw;

  right: 100vw;

  animation: ${slideInFromRight} 0.3s forwards;

  @media (max-width: 768px){
    width: 100vw;
    max-width: none;
  }
  
  z-index: 6;
  background: ${styles.secondaryDarkColor};
  overflow-y: auto;
  box-shadow: 0px 0px 10px black;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: ${styles.secondaryDarkColor};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${styles.formBackground};
    border-radius: 20px;
    border: 3px solid ${styles.secondaryDarkColor};
  }
  
`;

const Section = styled.div`
  padding: 30px;
  border-bottom: 1px solid ${styles.formBackground};
  display: flex;
  flex-direction: column;
  &:last-child {
    border-bottom: none;
  }
`;

const CloseButton = styled.div`
  position: absolute;
  top: 30px;
  right: 30px;
`;

const SelectedPreview = styled.p`
  font-size: 14px;
  strong {
    font-weight: bold;
  }
`;
