import * as React from "react";
import { connect } from "react-redux";
import { IStore } from "src/types/store/store";
import { RouteComponentProps } from "react-router-dom";
import { ThunkDispatch } from "redux-thunk";
import * as Redux from "redux";
import { CameraLocationList, TextInput } from "../../components";
import { ICameraLocation } from "src/store/camera-locations/camera-locations.api";
import {
  CameraTotals, getAllCameraLocationsRequest, getCameraLocationCountsRequest 
} from "../../store/camera-locations/camera-locations.actions";
import "react-table/react-table.css";
import { PaginatedParams, SortType } from "src/types/pagination";
import { PaginationState } from "@tanstack/react-table";
import styled from "styled-components";
import { styles } from "src/lib/styles";
import { ISiteRouterProps } from "./edit-site";
import { useSessionStorage } from "usehooks-ts";
import MobileSpacer from "src/components/MobileSpacer";
import { CameraFilter } from "src/components/camera-location-list/camera-location-list";

interface ICameraProps extends RouteComponentProps<ISiteRouterProps> {
  title: string;
}

interface IStateProps {
  cameras: ICameraLocation[];
  cameraLocationsCount: number;
  cameraTotals: CameraTotals;
  gettingCameraLocations: boolean;
}

interface ICameraDispatchProps {
  getCameraLocationCountsRequest: () => void;
  getAllCameraLocationsRequest: (
    pagination: PaginatedParams<ICameraLocation>,
    filters: Partial<ICameraLocation>
  ) => void;
}

type Props = ICameraProps & IStateProps & ICameraDispatchProps;

const CameraLocations: React.FC<Props> = ({
  getCameraLocationCountsRequest, getAllCameraLocationsRequest, cameras, history, cameraLocationsCount, gettingCameraLocations, cameraTotals
}) => {
  const [pagination, setPagination] = useSessionStorage<PaginationState>("CameraLocationPaginationState", {
    pageSize: 100,
    pageIndex: 0
  });

  const [filters, setFilters] = React.useState<Partial<ICameraLocation>>({
    status: CameraFilter.Live,
    search_terms: ""
  });

  const [sortOrder, setSortOrder] = React.useState<SortType<ICameraLocation>>([["job_ref", "DESC"]]);

  /** Submit the form on enter */
  const handleKeypress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      submitFilters();
    }
  };

  const submitFilters = () => {
    // Always default to all when submitting a text search
    setFilters({
      ...filters,
      status: CameraFilter.All
    });

    const newSearchTermFilters = {
      ...filters,
      status: CameraFilter.All 
    };

    getAllCameraLocationsRequest({
      limit: pagination.pageSize,
      offset: pagination.pageIndex * pagination.pageSize,
      sort: [["job_ref", "DESC"]],
      page: 1
    }, newSearchTermFilters);
  };

  React.useEffect(() => {
    getCameraLocationCountsRequest();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    onFetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pagination,
    // Don't refetch when filters.search_terms changes - that should be triggered on enter/submit press
    filters.status,
    filters.is_finished,
    sortOrder
  ]);

  const onFetchData = () => {
    getAllCameraLocationsRequest({
      limit: pagination.pageSize,
      offset: pagination.pageIndex * pagination.pageSize,
      sort: sortOrder,
      page: pagination.pageIndex
    }, filters);
  };

  const setCameraStatus = (cameraLocationStatus: string | undefined) => {
    setFilters({
      ...filters,
      status: cameraLocationStatus
    });

    if (cameraLocationStatus === CameraFilter.Finished) {
      setSortOrder([["latest_image", "DESC"]]);
    } else {
      setSortOrder([["job_ref", "DESC"]]);
    }

    setPagination({
      ...pagination,
      pageIndex: 0
    });
  };

  return (
    <React.Fragment >
     
      <div style={{
        paddingLeft: "5px",
        paddingRight: "5px",
        paddingTop: "20px",
        paddingBottom: "60px"
      }}> 
        <CameraLocationList
          rhsCallToAction={
            <Styled.SearchContainer>
              <TextInput
                placeholder="Search cameras"
                onChange={e => setFilters({
                  ...filters,
                  search_terms: e.target.value 
                })}
                onKeyPress={e => handleKeypress(e)}
                value={filters.job_ref} />
              <Styled.CTAButton
                onClick={() => submitFilters()}
              >
                Search
              </Styled.CTAButton>
            </Styled.SearchContainer>
          }
          onFetchData={() => onFetchData()}
          setCameraStatus={status => setCameraStatus(status)}
          cameraStatus={filters.status}
          cameraLocations={cameras}
          count={cameraLocationsCount}
          loading={gettingCameraLocations}
          history={history}
          pagination={pagination}
          setPagination={setPagination}
          totals={cameraTotals}
          setSortOrder={setSortOrder}
        
        />
      </div>
      <MobileSpacer/>
    </React.Fragment>
  );
};

const mapStateToProps = (state: IStore): IStateProps => {
  return {
    cameraLocationsCount: state.cameraLocations.cameraLocationsCount,
    cameras: state.cameraLocations.cameraLocations,
    gettingCameraLocations: state.cameraLocations.gettingCameraLocations,
    cameraTotals: state.cameraLocations.cameraLocationsTotals
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<IStore, void, Redux.Action>): ICameraDispatchProps => {
  return { 
    getCameraLocationCountsRequest: () => dispatch(getCameraLocationCountsRequest()),
    getAllCameraLocationsRequest: (pagination: PaginatedParams<ICameraLocation>, filters: Partial<ICameraLocation>) => dispatch(getAllCameraLocationsRequest(pagination, filters)) 
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CameraLocations);

const Styled = {
  CTAButton: styled.button`
    background: ${styles.primaryAccentColor};
    border: none;
    border-radius: 6px;
    color: ${styles.primaryDarkColor};
    padding: 8px 12px;
    margin-left: 10px;
    cursor: pointer;
    &:disabled {
      background: ${styles.formBackground};
      cursor: not-allowed;
    }
  `,
  SearchContainer: styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

  @media screen and (max-width: ${styles.mobileBreakPoint}) {
  margin-top: 10px;
  }
  `
  
};