import styled from "styled-components";
import moment from "moment";
import getImageByType from "src/lib/getImageByType";
import { IThumbnail } from "src/types/store/images";
import {
  Dispatch, useEffect, useState 
} from "react";
import React from "react";
import { connect } from "react-redux";
import { refreshLatestImageRequest } from "src/store/camera-locations/camera-locations.actions";

interface ILatestImageTableProps {
  thumbnails: IThumbnail[];
  taken_at: string;
  cameraLocationId: number;
  onFetchData: () => void;
  refreshLatestImage?: (cameraLocationId: number, callBack: () => void) => void;
}

const LatestImage = styled.img`
  max-width: 300px;
  min-width: 100px;
  width: 100%;
  border-radius: 5px;
  cursor: pointer;
`;

const LatestContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const LatestLabel = styled.div`
  padding-top: 5px;
`;

const LatestImageTable: React.FC<ILatestImageTableProps> = ({
  thumbnails, taken_at, cameraLocationId, refreshLatestImage, onFetchData
}) => {
  const [url, setUrl] = useState<string>("");
  const [formattedTakenAt, setFormattedTakenAt] = useState<string>("");

  const fetchImage = async () => {
    const imageUrl = await getImageByType("thumbnail", thumbnails);
    
    if (imageUrl.s3_url) {
      setUrl(imageUrl.s3_url);
      setFormattedTakenAt(moment.utc(taken_at).format("DD-MM-YYYY HH:mm:ss"));
    }
  };

  useEffect(() => {
    fetchImage();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thumbnails, taken_at]);

  return (
    <LatestContainer>
      <LatestImage onClick={() => refreshLatestImage?.(cameraLocationId, onFetchData)} src={url} />
      <LatestLabel>{formattedTakenAt}</LatestLabel>
    </LatestContainer>
  );
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({ refreshLatestImage: (cameraLocationId: number, callBack: () => void) => dispatch(refreshLatestImageRequest(cameraLocationId, callBack)) });

export default connect(null, mapDispatchToProps)(LatestImageTable);
