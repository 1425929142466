export interface IPoint {
  x: number;
  y: number;
}

const rangeBind = (
  lowerBound: number, upperBound: number, value: number
) =>
  Math.min(upperBound, Math.max(lowerBound, value));

const invert = (value: number) => value * -1;

const getRelativePosition = (event: { clientX: number; clientY: number },
  relativeToElement: HTMLElement) => {
  const rect = relativeToElement.getBoundingClientRect();

  return {
    x: event.clientX - rect.left,
    y: event.clientY - rect.top
  };
};

const getMidpoint = (pointA: IPoint, pointB: IPoint) => ({
  x: (pointA.x + pointB.x) / 2,
  y: (pointA.y + pointB.y) / 2
});

const getDistanceBetweenPoints = (pointA: IPoint, pointB: IPoint) =>
  Math.sqrt(Math.pow(pointA.y - pointB.y, 2) + Math.pow(pointA.x - pointB.x, 2));

export {
  rangeBind,
  invert,
  getRelativePosition,
  getMidpoint,
  getDistanceBetweenPoints
};
